import 'objectFitPolyfill';
import 'unorm';

import { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { Jumbotron } from 'components/jumbotron/jumbotron';
import { Footer } from 'components/footer/footer';

const imgHelper = () => {
  const imgs = Array.from(document.querySelectorAll('img'));
  imgs.forEach((img) => {
    img.setAttribute('data-object-fit', 'cover');
  });
  window.objectFitPolyfill();
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    content: {
      padding: '100px 0',
    },
  })
);

export function IENotSupported() {
  const classes = useStyles();

  useEffect(() => {
    imgHelper();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Jumbotron title="Vítejte v Badatelně Archivu Univerzity Karlovy" />
        <Grid container className={classes.content}>
          <Grid item xs={1} md={3} />
          <Grid item xs={10} md={6}>
            <Typography variant="body1" align="left">
              <b>Internet Explorer</b> nepatří mezi podporované prohlížeče.
              <br />
              Použijte prosím jiný prohlížeč.
            </Typography>
            <Grid item xs={1} md={3} />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}
